import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/book-logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getStoredCart, getStoredWishlist } from '../../../Utilities/LocalStorage';
import './Search.css';
import { url } from '../../../App';
import useAdmin from '../../../Hooks/useAdmin';

const Search = () => {
  const [title, setTitle] = useState('');
  const [searchBooks, setSearchBooks] = useState([]);
  const [cartLength, setCartLength] = useState(0);
  const [wishlistLength, setWishlistLength] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const [admin] = useAdmin(user?.phone_number);
  const token = localStorage.getItem('token');

  const history = useHistory();
  const location = useLocation();

  // Cart and wishlist length
  const cart_length = Object.keys(getStoredCart()).length;
  const wishlist_length = Object.keys(getStoredWishlist()).length;

  useEffect(() => {
    setCartLength(cart_length);
    setWishlistLength(wishlist_length);
  }, [cart_length, wishlist_length]);

  // Handle focus out
  const handleFocusOut = () => {
    setSearchBooks([]);
    setTitle('');
  };

  // Search function with debounce
  useEffect(() => {
    if (title.length > 2) {
      // Clear the previous timeout if user types again before it expires
      if (debounceTimeout) clearTimeout(debounceTimeout);

      // Set a new timeout to fetch data after the user stops typing
      const timeoutId = setTimeout(() => {
        fetch(`${url}/vector-search/?q=${title}`)
          .then(res => res.json())
          .then(result => {
            setSearchBooks(result?.data || []);
          });
      }, 500); // Debounce delay of 500ms

      setDebounceTimeout(timeoutId);
    } else {
      setSearchBooks([]);
    }

    // Cleanup on component unmount
    return () => {
      if (debounceTimeout) clearTimeout(debounceTimeout);
    };
  }, [title]);

  // Logout function
  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    fetch(`${url}/logout/`, {
      method: "POST",
      headers: {
        "Authorization": "Token " + token
      },
    }).then(() => {
      history.push('/');
    });
  };

  return (
    <>
      <section onMouseLeave={handleFocusOut} className="search container">
        <div className="d_flex row gx-0 d-flex flex-column flex-md-row justify-content-center align-items-center">
          <div className="col-12 col-md-2 d-flex justify-content-center justify-content-md-start align-items-center">
            <Link to="/">
              <img src={logo} height="70px" width="85px" alt="Nilkhet Boighor" />
            </Link>
          </div>
          <div className="d-flex justify-content-center align-items-center position-relative my-3 my-md-0 col-10 col-md-5">
            <div className="mx-auto w-100 border p-2 rounded-pill">
              <i className="fa fa-search px-2 px-md-3"></i>
              <input
                type="text"
                value={title}
                className="fs-6 w-75"
                placeholder="Search by Book, Author, Publisher, Category"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
              {
                searchBooks.length > 1 && (<div  className="bg-white table-responsive position-absolute border rounded search-div w-100">
                  <ul className="list-group list-group-flush w-100 fixed-header">
                    {searchBooks.map((item, index) => (
                      <li key={index} className="container mx-auto list-group-item">
                        <div className="row">
                          <div className="col-lg-2 col-md-3 col-sm-4">
                            <Link to={`/books/${item.id}`}>
                              <img src={url + item.image} height="40px" alt="" />
                            </Link>
                          </div>
                          <div className="col-lg-7 col-md-6 col-sm-8 text-start">
                            <Link to={`/books/${item.id}`}>{item.english_title}</Link>
                            <p>{item?.discount_price ? `${item?.discount_price} টাকা` : `${item?.regular_price} টাকা`}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>)
              }
    
          </div>

          <div className="d-flex col-12 col-md-5 justify-content-center justify-content-md-end align-items-center">
            {admin.length !== 0 && (
              <Link to="/admin">
                <i className="fa-solid fa-user icon-circle"></i>
              </Link>
            )}
            {user?.phone_number ? (
              <i className="fa-solid fa-right-from-bracket icon-circle logout-hover" onClick={logout} />
            ) : (
              <Link to="/login">
                <i className="fa-solid fa-user-plus icon-circle"></i>
              </Link>
            )}
            <div className="cart">
              <Link to="/wishlist">
                <i className="fa-solid fa-heart icon-circle"></i>
                <span>{wishlistLength !== 0 && wishlistLength}</span>
              </Link>
            </div>
            <div className="cart">
              <Link to="/cart">
                <i className="fa fa-shopping-bag icon-circle"></i>
                <span>{cartLength !== 0 && cartLength}</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Search;
