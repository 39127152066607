import React, { useState, useEffect } from 'react';
import useLocalCart from '../../../Hooks/useLocalCart';
import { addToDb } from '../../../Utilities/LocalStorage';
import Footer from '../../common/footer/Footer';
import Header from '../../common/header/Header';
import MegaMenu from '../../common/MegaMenu/MegaMenu';
import {useParams} from 'react-router-dom';
import PublisherDetail from './PublisherDetail';
import usePublisherBooksSortByNew from '../../../Hooks/Sorting/SortingPublisherBooks/usePublisherBooksSortByNew';
import usePublisherBooksSortByStock from '../../../Hooks/Sorting/SortingPublisherBooks/usePublisherBooksSortByStock';
import usePublisherBooksSortByHighPrice from '../../../Hooks/Sorting/SortingPublisherBooks/usePublisherBooksSortByHighPrice';
import usePublisherBooksSortByLowPrice from '../../../Hooks/Sorting/SortingPublisherBooks/usePublisherBooksSortByLowPrice';
import Pagination from '../../Shared/Pagination/Pagination';
import { url } from '../../../App';


export default function PublisherDetails() {
  const {publisherName} = useParams();
  const [books, setPublisherBooks] = useState([]);
  const [CartItem, setCartItem]= useLocalCart(books);
  const [sorted_books, setSortedBooks] = useState([]);
  const [sortedNewBooks] = usePublisherBooksSortByNew(publisherName);
  const [sortedStockBooks] = usePublisherBooksSortByStock(publisherName);
  const [sortedHighBooks] = usePublisherBooksSortByHighPrice(publisherName);
  const [sortedLowBooks] = usePublisherBooksSortByLowPrice(publisherName);
  const [sortingValue, setSortingValue] = useState('');
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [displayTotalBooks, setDisplayTotalBooks] = useState([]);
  const [offsetBooks, setOffsetBooks] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [displayBooks, setDisplayBooks] = useState([]);
  const [offset, setOffset] = useState(0);


  //pagination page click function
  const handleTotalPageClick=(e)=>{
    const selectedPage = e.selected;
    setOffsetBooks(selectedPage*20);
  }
  const handlePageClick=(e)=>{
    const selectedPage = e.selected;
    setOffset(selectedPage*20);
  }

  //default sort function
  const selectDefaultSort = () =>{
    setSortingValue('');
  }

  //sorting function
  const selectSort = (e) =>{
    setSortingValue(e.target.value);
  }

  useEffect(() => {
    let data =[];  

    fetch(`${url}/publisher-book/${publisherName}/`)
     .then(res => res.json())
     .then(result => {
      setPublisherBooks(result);
      const pageNumber = (Math.floor(result.length/20))+1;
      setPageCount(pageNumber);
      setDisplayBooks(result.slice(offset, (offset+(20*1))));
    });


    if(sortingValue === "highest"){
       data = sortedHighBooks;
       setSortedBooks(data);
    }
    if(sortingValue === "lowest"){
       data = sortedLowBooks;
       setSortedBooks(data);
    }
    if(sortingValue === "inStock"){
       data = sortedStockBooks;
       setSortedBooks(data);
    }
    if(sortingValue === "new"){
       data=sortedNewBooks;
       setSortedBooks(data);
    }

    const pageNumber = (Math.floor(data.length/20))+1;
    setTotalPageCount(pageNumber);
    setDisplayTotalBooks(data.slice(offsetBooks, (offsetBooks+(20*1))));

   },[publisherName, sortingValue, offset, offsetBooks]);


  // cart function
  const handleAddToCart=(book)=>{
    const newCart=[...CartItem, book];
    setCartItem(newCart);
    addToDb(book.id);
    alert('Successfully added!');
  }


  return (
    <>
      <Header CartItem={CartItem}></Header>
      <MegaMenu></MegaMenu>
      <div className="container mx-auto m-3 mb-5">
        <h4 className="pages-heading text-center fw-bold">{publisherName} Books</h4>
        <hr className="bg-success w-25 mx-auto" />
        {(books.length===0 && displayTotalBooks.length===0) && 
        <div className='vh-100'></div>
        }
        {(books.length!==0 || displayTotalBooks.length!==0) &&
        <section className=''>
          <div className="sort-filter m-3 d-flex justify-content-end">
            <div className='sort'>
              <form action="#" className=''>
                <label htmlFor='sort'></label>
                <select name='sort' id='sort' className='sort-selection bg-light p-2 rounded custom-border-outline custom-text fw-medium p-2' onChange={(e)=>selectSort(e)}>
                  <option value="default"  onClick={selectDefaultSort}>Default Sorting</option>
                  <option value="lowest">Price low to high</option>
                  <option value="highest">Price high to low</option>
                  <option value="inStock">In stock</option>
                  <option value="new">New arrivals</option>
                </select>
              </form>
            </div>
          </div>
          <div className="">
            {(sortingValue==='' && displayBooks?.length!==0) &&
            <>
              {displayBooks?.length<=4 ?
                <div  style={{height:'1000px'}}>
                  <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-5 mx-auto">
                  {books.map((book) => (
                    <PublisherDetail key={book.id} book={book} 
                    handleAddToCart={handleAddToCart}></PublisherDetail>
                  ))}
                  </div>
                </div>
              :
                <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-5 mx-auto">
                  {books.map((book) => (
                    <PublisherDetail key={book.id} book={book} 
                    handleAddToCart={handleAddToCart}></PublisherDetail>
                  ))}
                </div>
              }
              <div className='container w-75 mx-auto mt-5'>              
                <Pagination pageCount={pageCount} handlePageClick={handlePageClick} offset={offset}></Pagination>
              </div>
            </>
            }
            {(sortingValue!=='' && sorted_books?.length!==0) &&
              <>
                {(sorted_books?.length<=4) ?
                  <div  style={{height:'1000px'}}>
                    <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-5 mx-auto">
                    {displayTotalBooks.map((book) => (
                      <PublisherDetail key={book.id} book={book} 
                      handleAddToCart={handleAddToCart}></PublisherDetail>
                    ))}
                    </div>
                  </div>
                :
                  <div className="g-4 row row-cols-1 row-cols-md-3 row-cols-lg-5 mx-auto">
                    {displayTotalBooks.map((book) => (
                    <PublisherDetail key={book.id} book={book} 
                    handleAddToCart={handleAddToCart}></PublisherDetail>
                    ))}
                  </div>
                }
                <div className='container w-75 mx-auto mt-5'>              
                  <Pagination pageCount={totalPageCount} handlePageClick={handleTotalPageClick} offset={offsetBooks}></Pagination>
                </div>
              </>
            }
          </div>
        </section>
        }
      </div>
      <Footer></Footer>
    </>
  );
}
