import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory } from 'react-router-dom';
import useCategoryByID from '../../../Hooks/CallByID/useCategoryByID';
import {url} from '../../../App';

export default function UpdateCategory({categories}) {
  const [details, setDetails] = useState({});
  const { id } = useParams();
  const {register, handleSubmit, reset, formState: {errors}} = useForm();
  const history = useHistory();

  useEffect(() => {
    const matchCategory = categories.find((category) => category.id == id);
    setDetails(matchCategory);
  }, [id, categories]);
  
  const [singleCategory] = useCategoryByID(details?.parent)

  // category updated function
  const onSubmit = data => {
      fetch(`${url}/category/${details.name}/`, {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
      })
      .then((res) => res.json())
      .then((result) =>{
        if(result.status==400){
          alert(result.message);
          reset();
        }
        else{
          alert("Category updated Successfully!");
          reset();
          history.push('/admin-categories');
        }     
      });
  }

  return (
    <div className="container mx-auto w-75 mt-5 border-0 p-3 rounded shadow bg-light">
      <h3 className="text-center ">Update category Information</h3>
      <hr className="w-50 mx-auto" />
      <form className="w-50 mx-auto " onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("name", {required: true})}
          defaultValue={details?.name}
          placeholder="category name"
          className="p-3 m-3 w-100 border border-success rounded"
        />
        {errors.name?.type === 'required' && <p role="alert" className="text-danger "><small>ক্যাটাগরীর নাম্ অত্যাবশ্যকিয়*</small></p>}
        <br />
        <select className="p-3 m-3 w-100 border border-success rounded bg-white" {...register("parent")}>
          {singleCategory?.name && 
          <option defaultValue={singleCategory?.name} >{singleCategory.name}</option>
          }
          <option disabled >একটি প্যারেন্ট ক্যাটাগরী
               নির্বাচন করুন</option>
          {categories.map((category) => (
                <option value={category.id} key={category.id}>{category.name}</option>
          ))}
        </select>
        <div className='d-flex w-50 mx-auto'>
          <input
            type="submit"
            value="Update"
            className="btn btn-success w-50 border border-success rounded m-3"
          />
          <NavLink to='/admin-categories' className='text-white btn btn-danger w-50 border border-danger rounded m-3 '>Cancel <i className="fa-solid fa-close"></i></NavLink>
        </div>
      </form>
    </div>
  );
}
