import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import useCategoryByID from '../../../Hooks/CallByID/useCategoryByID';
import useAuthorByID from '../../../Hooks/CallByID/useAuthorByID';
import usePublisherByID from '../../../Hooks/CallByID/usePublisherByID';
import { useForm } from 'react-hook-form';
import {url} from '../../../App';


export default function UpdateBook({books, categories, authors, publishers}) {
  const [details, setDetails] = useState({});
  const { id } = useParams();
  const {register, handleSubmit, reset} = useForm();
  const history = useHistory();
  const [ selectedImage,setSelectedImage ] = useState(null);
  const [ selectedPDF,setSelectedPDF ] = useState(null);
  const [offerType, setOfferType] = useState('');
  
  useEffect(() => {
    const matchBook = books.find((book) => book.id == id);
    setDetails(matchBook);
    setOfferType(matchBook?.offer_type);
  }, [id, books]);

  const [singleCategory] = useCategoryByID(details?.category);
  const [author] = useAuthorByID(details?.author);
  const [publisher] = usePublisherByID(details?.publisher);

  //offer_type select
  const selectOfferType = (e) =>{
    setOfferType(e.target.value);
  }

  //books updated function
  const onSubmit = data => {

    let formData = new FormData();
    
    if(!data.purchase_price){
      data.purchase_price=details?.purchase_price;
    }
    if(!data.regular_price){
      data.regular_price=details?.regular_price;
    }
    if(!data.discount_price){
      data.discount_price=details?.discount_price;
    }
    if(!data.percentage){
      data.percentage=details?.percentage;
    }
    if(!data.flat_discount){
      data.flat_discount=details?.flat_discount;
    }
    if(offerType==='' || offerType==='null'){
      data.offer_type='';
      data.discount_price=0;
      data.percentage = 0;
      data.flat_discount = 0;
    }
    if(offerType==='Percentage'){
      data.offer_type = offerType;
      let percentage_value = data.regular_price*(data.percentage/100);
      data.discount_price = parseInt(data.regular_price-percentage_value);
      data.flat_discount = 0;
    }
    if(offerType==='Flat Discount'){
      data.offer_type = offerType;
      data.discount_price = parseInt(data.regular_price-data.flat_discount);
      data.percentage = 0;
    }
    if(data.units_sold===''){
      data.units_sold=0;
    }
    if(selectedPDF!==null){
      formData.append('pdf', selectedPDF);
    }
    if(data.description===''){
      data.description = '';
    }
    if(data.total_page){
      formData.append('total_page', data.total_page);
    }
    if(!data.english_title){
      data.english_title = details?.english_title;
    }
    if(!data.bangla_title){
      data.bangla_title = details?.bangla_title;
    }
    if(!data.category){
      data.category = details?.category;
    }
    if(!data.author){
      data.author = details?.author;
    }
    if(!data.publisher){
      data.publisher = details?.publisher;
    }
    if(selectedImage!==null){
      formData.append('image', selectedImage);
    }
    if(!data.alt_text){
      if(!details?.alt_text){
        data.alt_text = data.english_title;
      }
      else{
        data.alt_text = details?.alt_text;
      }
    }
    formData.append('english_title', data.english_title);
    formData.append('bangla_title', data.bangla_title);
    formData.append('category', data.category);
    formData.append('description', data.description);
    formData.append('type', 'books');
    formData.append('author', data.author);
    formData.append('publisher', data.publisher);
    formData.append('isbn', data.isbn);
    formData.append('edition', data.edition);
    formData.append('language', data.language);
    formData.append('country', data.country);
    formData.append('offer_type', data.offer_type);
    formData.append('percentage', data.percentage);
    formData.append('flat_discount', data.flat_discount);
    formData.append('purchase_from', data.purchase_from);
    formData.append('purchase_price', data.purchase_price);
    formData.append('regular_price', data.regular_price);
    formData.append('discount_price', data.discount_price);
    formData.append('units_stock', data.units_stock);
    formData.append('units_sold', data.units_sold);
    formData.append('is_active', 'true');
    formData.append('alt_text', data.alt_text);
    
    fetch(`${url}/api/product/${id}/`, {
      method: "PUT",
      body: formData,
    })
    .then(res => {
      if (!res.ok) throw res;
      else return res.json();
    })
    .then(getData => {
      alert("Book updated Successfully!");
      reset();
      history.push('/admin-books');
    })
    .catch(err => {
      err.json().then(text => {
        if (text?.error) {
          console.log(text?.error);
          return;
        }
      })
      console.log(err.statusText);
    });
    
  }

  return (
    <div className="container mx-auto w-75 m-3 border-0 p-3 rounded shadow bg-light">
      <h3 className="text-center ">Update Book Information</h3>
      <hr className="w-50 mx-auto" />
      <form className="container "  onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
            <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Book English Title:</label>
            <input
              {...register("english_title")}
              defaultValue={details?.english_title}
              type="text"
              placeholder="Enter book's english title"
              className=" p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
            />

            <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Book Bangla Title:</label>
            <input
              {...register("bangla_title")}
              defaultValue={details?.bangla_title}
              type="text"
              placeholder="Enter book's bangla title"
              className=" p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
            />    
        </div>
        <div className="row mb-3 ">     
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Category:</label>
          <select className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded" {...register("category")}>
              <option value={details?.category} key={details?.category}>{singleCategory.name}</option>
              {categories.map((category) => (
                <option value={category.id} key={category.id}>{category.name}</option>
              ))}
          </select> 
        </div>
        <div className="row mb-3 ">
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Description:</label>
          <textarea
            {...register("description")}
            defaultValue={details?.description}
            type="text"
            placeholder="description"
            className="p-2 col-sm-12 col-md-10 col-lg-10 border border-success rounded"
            cols="60" rows="10"
          />
        </div>
        <div className="row mb-3 ">
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Author:</label>
            <select className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded" {...register("author")}>
              <option value={details?.author} key={details?.author}>{author.name}</option>
                {authors.map((author) => (
                  <option value={author.id} key={author.id}>{author.name}</option>
                ))}
            </select>
                
            <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Publisher:</label>
            <select className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded" {...register("publisher")}>
              <option value={details?.publisher} key={details?.publisher}>{publisher.name}</option>
                {publishers.map((publisher) => (
                  <option value={publisher.id} key={publisher.id}>{publisher.name}</option>
                ))}
            </select>   
        </div>
        {details?.image && <img src={details?.image} alt={details?.alt_text} height="100px" width="100px" className="p-2"/>}
        <div className="row mb-3 ">
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Upload image:</label>
          <input
            {...register("image")}
            type="file"
            id="choose-file"
            placeholder="Upload an Book image"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
            data-max-file-size="2M"
            name="upload"
            accept="image/*"
            onChange={(event) => {setSelectedImage(event.target.files[0])}}
          />

          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Alt-text:</label>
          <input
            {...register("alt_text")}
            defaultValue={details?.alt_text}
            type="text"
            placeholder="alternate text"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
        </div>
        <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">ISBN:</label>
                <input
                  {...register("isbn")}
                  defaultValue={details?.isbn}
                  type="text"
                  placeholder="isbn no."
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Edition:</label>
                <input
                  {...register("edition")}
                  defaultValue={details?.edition}
                  type="text"
                  placeholder="edition"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
        </div>
        <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Language:</label>
                <input
                  {...register("language")}
                  defaultValue={details?.language}
                  type="text"
                  placeholder="language"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Country:</label>
                <input
                  {...register("country")}
                  defaultValue={details?.country}
                  type="text"
                  placeholder="country"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
        </div>
        {details?.pdf && <img src={details?.image} alt={details?.alt_text} height="100px" width="100px" className="p-2"/>}
        <div className="row mb-3 ">
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Total page:</label>
          <input
            {...register("total_page")}
            defaultValue={details?.total_page}
            type="number"
            min="0"
            placeholder="total_page"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">PDF:</label>
          <input
            {...register("pdf")}
            defaultValue={details?.pdf}
            type="file"
            id="choose-file"
            placeholder="Upload an Book PDF"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
            name="upload"
            accept=".pdf"
            onChange={(event) => {setSelectedPDF(event.target.files[0]) }}
          />
        </div>
        <div className="row mb-3 ">
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Purchase from:</label>
          <textarea
            {...register("purchase_from")}
            defaultValue={details?.purchase_from}
            type="text"
            placeholder="purchase from"
            className="p-2 col-sm-12 col-md-10 col-lg-10 border border-success rounded"
            cols="20" rows="4"
          />
        </div>
        <div className="row mb-3 ">
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Offer type:</label>
          {details?.offer_type!=='' &&
          <>
          <select className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded" {...register("offer_type")}  onChange={(e)=>selectOfferType(e)}>
            {details?.offer_type && <option value={details?.offer_type} selected>{details?.offer_type}</option>}
            <option value='null'>একটি অফার টাইপ নির্বাচন করুন</option>
            <option value='Percentage'>Percentage</option>
            <option value='Flat Discount'>Flat Discount</option>
          </select>
          {offerType==='Percentage' &&
          <>
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Percentage:</label>
          <input
            {...register("percentage")}
            type="number"
            defaultValue={details?.percentage}
            min="0"
            placeholder="Percentage value"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
          </>
          }
          {offerType==='Flat Discount' &&
          <>
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Flat Discount:</label>
          <input
            {...register("flat_discount")}
            type="number"
            min="0"
            defaultValue={details?.flat_discount}
            placeholder="Flat Discount value"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
          </>
          }
          </>
          }
          {details?.offer_type==='' &&
          <>
          <select className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded" {...register("offer_type")}  onChange={(e)=>selectOfferType(e)}>
            <option value='null' selected>একটি অফার টাইপ নির্বাচন করুন</option>
            <option value='Percentage'>Percentage</option>
            <option value='Flat Discount'>Flat Discount</option>
          </select>
          {(offerType==='Percentage') && 
          <>
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Percentage:</label>
          <input
            {...register("percentage")}
            type="number"
            min="0"
            placeholder="Percentage value"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
          </>
          }
          {(offerType==='Flat Discount') && 
          <>
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Flat Discount:</label>
          <input
            {...register("flat_discount")}
            type="number"
            min="0"
            placeholder="Flat Discount value"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
          </>
          }
          </>
          }
        </div>
        <div className="row mb-3 ">
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Purchase price:</label>
          <input
            {...register("purchase_price")}
            defaultValue={details?.purchase_price}
            type="number"
            min="0"
            placeholder="purchase price"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
          <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Regular price:</label>
          <input
            {...register("regular_price")}
            defaultValue={details?.regular_price}
            type="number"
            min="0"
            placeholder="regular price"
            className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
          />
        </div>
        <div className="row mb-3 ">
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Stock:</label>
                <input
                {...register("units_stock")}
                defaultValue={details?.units_stock}
                type="number"
                min="0"
                placeholder="stock in hand"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
                
                <label className="col-sm-12 col-md-2 col-lg-2 py-2 ">Sold:</label>
                <input
                  {...register("units_sold")}
                  defaultValue={details?.units_sold}
                  type="number"
                  min="0"
                  placeholder="sold quantity"
                  className="p-2 col-sm-12 col-md-4 col-lg-4 border border-success rounded"
                />
        </div>
        <div className='d-flex w-50 mx-auto'>
          <input
            type="submit"
            value="Update"
            className="btn btn-success w-50 border border-success rounded m-3"
          />
          <NavLink to='/admin-books' className='text-white btn btn-danger w-50 border border-danger rounded m-3 '>Cancel <i className="fa-solid fa-close"></i></NavLink>
        </div>
      </form>
    </div>
  );
}
